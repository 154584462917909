import { FC, useEffect, useState } from "react";
import { SubmitModal } from "../Modal";
import { GhostBtn, PrimaryBtn } from "../Buttons";
import LoadingContainer from "../LoadingContainer";
import { ModalFormContainer } from "../Modal/styles";
import { FormError, FormTextareaInput } from "../FormComponents";
import { countUnresolvedAlerts, postEditAlerts, postEditBulkAlerts } from "../../services/alertNotifications";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import addOrUpdate from "../../util/addOrUpdate";
import { kegOrTracker } from "../../util/kegOrTracker";

const ResolveAlertsModal: FC<any> = ({ alert = {}, resolveAllForTracker = false, setData, modalOpen, setModalOpen }) => {
  const [formData, setFormData] = useState({ alertResolutionNote: "" });

  const [alertResolutionError, setAlertResolutionError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<any>({
    resolutionNote: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(1);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");

  useEffect(() => {
    if (modalOpen) {
      setAlertResolutionError("");
      setFormErrors({
        alertResolutionNote: false,
      });
      setFormData({
        ...formData,
        alertResolutionNote: alert.resolutionNote || "",
      });
    }
  }, [modalOpen, alert.resolutionNote]);

  const validateForm = () => {
    if (formData.alertResolutionNote.length > 1000) {
      setFormErrors((prev: any) => ({
        ...prev,
        alertResolutionNote: `Resolution note cannot be over 1000 chars long. Current Length: ${formData.alertResolutionNote.length}`,
      }));
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!resolveAllForTracker || !alert.sensorId) {
      return;
    }
    const source = axios.CancelToken.source();

    setLoading(true);
    countUnresolvedAlerts(source, alert.sensorId)
      .then((response) => {
        setCount(response);
        setAlertResolutionError("");
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setAlertResolutionError(errToStr(err));
          setLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [alert.sensorId || resolveAllForTracker]);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: false });
  };

  const source = axios.CancelToken.source() as CancelTokenSource;
  const handleSubmit = () => {
    if (!alert.alertId || !alert.sensorId) {
      setAlertResolutionError("Alert object is missing required keys: alertId or sensorId.");
      return;
    }

    const valid = validateForm();

    if (valid) {
      const body = {
        action: "resolve",
        resolutionNote: formData.alertResolutionNote,
        ...(resolveAllForTracker ? { sensorId: alert.sensorId } : { alertId: alert.alertId }),
      };

      setSubmitting(true);
      if (resolveAllForTracker) {
        postEditBulkAlerts(source, body)
          .then((response: [any]) => {
            response.forEach((alert: any) => {
              setData((prev: []) => addOrUpdate(prev, alert, "alertId"));
            });
            setSubmittedMsg(`All alerts for ${alert.sensorId} were resolved`);
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
        return;
      } else {
        postEditAlerts(source, body)
          .then((response: any) => {
            setData((prev: any) => addOrUpdate(prev, response, "alertId"));
            setSubmittedMsg(alert.resolutionNote && alert.resolutionNote != "" ? "Note Edited" : "Note Created");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              console.log("valid", body);
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        onClose={() => {
          if (!submitting) setModalOpen(false);
        }}
        size={!submittedMsg && !submittingErr ? "lg" : "sm"}
        title={"Resolution Notes"}
        success={submittedMsg}
        error={alertResolutionError || submittingErr}
        footer={
          submittedMsg || submittingErr ? (
            <PrimaryBtn onClick={() => setModalOpen(false)}>Okay</PrimaryBtn>
          ) : resolveAllForTracker && count == 0 ? (
            <PrimaryBtn onClick={() => setModalOpen(false)}>Okay</PrimaryBtn>
          ) : resolveAllForTracker && count > 1 ? (
            <>
              <GhostBtn onClick={() => setModalOpen(false)}>Leave Unresolved</GhostBtn>
              <PrimaryBtn onClick={handleSubmit}>{resolveAllForTracker ? `Resolve ${count} Alerts` : "Resolve"}</PrimaryBtn>
            </>
          ) : (
            <>
              <GhostBtn onClick={() => setModalOpen(false)}>Cancel</GhostBtn>
              <PrimaryBtn onClick={handleSubmit}>Confirm</PrimaryBtn>
            </>
          )
        }
        body={
          <LoadingContainer loading={loading || submitting}>
            {resolveAllForTracker && count == 0 ? (
              <span>
                All alerts for {kegOrTracker("Keg", "Tracker")} {alert.sensorId} are already resolved.
              </span>
            ) : (
              <>
                <form noValidate onSubmit={(e) => e.preventDefault()}>
                  <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                    Please explain how {count > 1 ? "these alerts were" : "this alert was"} resolved.
                  </ModalFormContainer>
                  <ModalFormContainer>
                    <FormTextareaInput
                      type="text"
                      rows={6}
                      maxLength={1000}
                      name="alertResolutionNote"
                      value={formData.alertResolutionNote}
                      onChange={handleChange}
                    />
                    <FormError error={formErrors.resolutionNote}>{formErrors.resolutionNote}</FormError>
                  </ModalFormContainer>
                </form>
              </>
            )}
          </LoadingContainer>
        }
      />
    </>
  );
};

export default ResolveAlertsModal;
