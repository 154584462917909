import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { FaPlay, FaStop } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { hideAll } from "tippy.js";
import { getAllContents } from "../../services/contents";
import { getManifest, getManifestLogs } from "../../services/manifests";
import { getManifestMap, ManifestMapDto } from "../../services/manifests";
import { fetchSensorCsv } from "../../services/sensorCsv";
import { fetchSensorPage } from "../../services/sensorPage";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { printFixedLength, printFixedTemp, printLengthUnit, printTempUnit } from "../../util/formatUnits";
import sortTags from "../../util/sortTags";
import { getTableFilters } from "../../util/urlParamFilters";
import useWindowSize from "../../util/useWindowSize";
import AlertLogsTable from "../AlertLogsTable";
import { OutlineBtn, PrimaryBtn } from "../Buttons";
import ChartHeading from "../ChartHeading";
import EditManifestModel from "../EditManifestModel";
import EndManifestModal from "../EndManifestModel";
import LoadingContainer from "../LoadingContainer";
import ManifestContentsTable from "../ManifestContentsTable";
import ManifestMap from "../ManifestMap";
import ManifestMeta from "../ManifestMeta";
import PageBreadcrumbs from "../PageBreadcrumbs";
import { PageContainer } from "../PageStyles";
import SensorHistory from "../SensorHistory";
import SensorLogs from "../SensorLogs";
import StartManifestModel from "../StartManifestModel";
import Tooltip from "../Tooltip";
import { MenuButton, MenuList } from "../Tooltip/styles";
import { AlertWrapper, ChartWrapper, FlexRow, HistoryWrapper, MapWrapper, MetaWrapper } from "./styles";
import ManifestMarkForPickupModal from "../ManifestMarkForPickupModal";

const ManifestScreen: FC = () => {
  const { color, short_date, time } = useContext(ThemeContext);

  const { manifestId = "0" } = useParams();

  const { width } = useWindowSize();

  const [trackerId, setTrackerId] = useState<string>("");
  const [manifestDates, setManifestDates] = useState<any>(undefined);

  const [manifest, setManifest] = useState<any>({});
  const [manifestErr, setManifestErr] = useState<string>("");

  const [tracker, setTracker] = useState<any>({
    meta: {},
    hops: [],
    places: [],
    history: [],
  });
  const [trackerErr, setTrackerErr] = useState<string>("");

  // New state for the manifest map data
  const [manifestMapData, setManifestMapData] = useState<ManifestMapDto>({
    id: 0,
    status: "",
    sensorId: "",
    description: "",
    points: [],
  });
  const [manifestMapErr, setManifestMapErr] = useState<string>("");
  const [manifestMapLoading, setManifestMapLoading] = useState<boolean>(false);

  const [manifestLogs, setManifestLogs] = useState<any>({ series: [], logs: [] });
  const [manifestLogsLoading, setManifestLogsLoading] = useState<boolean>(false);
  const [manifestLogsErr, setManifestLogsErr] = useState<string>("");

  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [events, setEvents] = useState<any>([]);

  const [series, setSeries] = useState<any>([]);
  const [pieces, setPieces] = useState<any>([]);
  const [meta, setMeta] = useState<any>({ logLimit: false });
  const [cursor, setCursor] = useState<any>(Infinity);

  const [contentsCsv, setContentsCsv] = useState<any>(undefined);
  const [contentsCsvLoading, setContentsCsvLoading] = useState<boolean>(false);

  const [trackerCsv, setTrackerCsv] = useState<any>(undefined);
  const [trackerCsvLoading, setTrackerCsvLoading] = useState<boolean>(false);

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [startManifestModalOpen, setStartManifestModalOpen] = useState<boolean>(false);
  const [endManifestModalOpen, setEndManifestModalOpen] = useState<boolean>(false);
  const [markForPickupModalOpen, setMarkForPickupModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const fetchManifest = () => {
    setDataLoading(true);
    setManifestErr("");

    if (manifestId !== undefined) {
      getManifest(source, manifestId)
        .then((response) => {
          setManifest(response);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setManifestErr(errToStr(err));
            setDataLoading(false);
          }
        });
    }
  };

  const fetchManifestMapData = () => {
    setManifestMapLoading(true);
    setManifestMapErr("");

    if (manifestId !== undefined) {
      getManifestMap(source, manifestId)
        .then((response) => {
          setManifestMapData(response);
          setManifestMapLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setManifestMapErr(errToStr(err));
            setManifestMapLoading(false);
          }
        });
    }
  };

  const fetchManifestLogs = () => {
    setManifestLogsLoading(true);
    setManifestLogsErr("");

    if (manifestId !== undefined) {
      getManifestLogs(source, manifestId)
        .then((response) => {
          // the logs from the api are "compressed" by removing values from each log if it is the same
          // as the previous log, this code adds those back in so the graphs are displayed correctly
          if (response.logs.length > 0) {
            // gets the logs from the response
            const { logs, meta, series } = response;

            // sets the meta data for logs (logLimit)
            setMeta(meta);

            // gets the log's keys from the series object
            const props = [];
            for (const [key, value] of Object.entries(series)) {
              if (value === true) props.push(key);
            }
            setSeries([...props, "const"]);

            // adds first log to new logs
            const newLogs = [{ ...logs[0], const: 1 }];
            const newPieces = [];

            // round all timestamps because echarts doesn't like decimals
            newLogs[0].ts = Math.round(newLogs[0].ts);

            // loops through all logs
            for (let i = 1; i < logs.length; i++) {
              // create new temp log to repopulate missing fields
              const tempLog = logs[i];

              // for each prop, check if missing and if so take value from previous log if it isn't a on/off type prop
              for (let j = 0; j < props.length; j++) {
                if (logs[i][props[j]] === undefined && props[j] !== "sample" && props[j] !== "lightInterrupt") {
                  tempLog[props[j]] = newLogs[i - 1][props[j]];
                }
              }

              // round all timestamps because echarts doesn't like decimals
              tempLog.ts = Math.round(tempLog.ts);
              tempLog.const = 1;

              // add temp log to new logs array
              newLogs[i] = tempLog;
              newPieces[i - 1] = { min: logs[i - 1].ts - 1, max: logs[i].ts + 1, color: logs[i - 1].placeGroupColour };
            }
            setManifestLogs(newLogs);
            setPieces(newPieces);
          } else {
            setManifestLogs([]);
          }
          setManifestLogsLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setManifestLogsErr(errToStr(err));
            setManifestLogsLoading(false);
          }
        });
    }
  };

  // Fetch CSV data for manifest when download button is clicked
  const fetchCsv = () => {
    fetchContentsCsv();
    fetchTrackerCsv();
  };

  // Fetch contents CSV data
  const fetchContentsCsv = () => {
    setContentsCsvLoading(true);

    getAllContents(source, true, { filters: getTableFilters([{ id: "manifestId", value: { type: "number", value: manifest.id } }]) })
      .then((response) => {
        setContentsCsv(response.data);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          toast.info(errToStr(err));
          setContentsCsvLoading(false);
        }
      });
  };

  // Fetch tracker CSV data
  const fetchTrackerCsv = () => {
    setTrackerCsvLoading(true);

    const dates = {
      start: manifest.startTimeUnix ? manifest.startTimeUnix : manifest.createdTimeUnix ? manifest.createdTimeUnix : moment().subtract(7, "days").unix(),
      end: manifest.endTimeUnix ? manifest.endTimeUnix : moment().unix(),
    };

    fetchSensorCsv(source, trackerId, dates)
      .then((response) => {
        setTrackerCsv(response);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          toast.info(errToStr(err));
          setTrackerCsvLoading(false);
        }
      });
  };

  // Format CSV data when trackerCsv and contentsCsv are loaded
  useEffect(() => {
    if (trackerCsv !== undefined && contentsCsv !== undefined) {
      let logs = [];

      if (trackerCsv.logs && trackerCsv.logs.length > 0) {
        logs = JSON.parse(JSON.stringify(trackerCsv.logs));
      }

      if (contentsCsv.length > 0) {
        contentsCsv.map((row: any) => {
          if (row.dateAddedUnix)
            logs.push({
              event: "Contents Added",
              date: row.dateAddedUnix,
              place: row.placeAddedName,
              user: row.userAdded,
              id: row.identifier,
              tags: row.contentTags
                .sort(sortTags)
                .map((tag: any) => tag.name)
                .join(", "),
              name: row.name,
            });

          if (row.dateRemovedUnix)
            logs.push({
              event: "Contents Removed",
              date: row.dateRemovedUnix,
              place: row.placeRemovedName,
              user: row.userRemoved,
              id: row.identifier,
              tags: row.contentTags
                .sort(sortTags)
                .map((tag: any) => tag.name)
                .join(", "),
              name: row.name,
            });
        });
      }

      if (manifest) {
        if (manifest.createdTimeUnix)
          logs.push({
            event: "Manifest Created",
            id: manifest.id,
            date: manifest.createdTimeUnix,
            user: manifest.createdUserName,
          });

        if (manifest.startTimeUnix)
          logs.push({
            event: "Manifest Started",
            id: manifest.id,
            date: manifest.startTimeUnix,
            user: manifest.startingUserName,
            place: manifest.startPlaceName,
          });

        if (manifest.endTimeUnix)
          logs.push({
            event: "Manifest Ended",
            id: manifest.id,
            date: manifest.endTimeUnix,
            user: manifest.endUserName,
            place: manifest.endPlaceName,
          });
      }

      // Sort logs by date in ascending order
      const sortedLogs = logs.sort((a: any, b: any) => a.date - b.date);

      formatCsv(sortedLogs);

      setTrackerCsv(undefined);
      setContentsCsv(undefined);
    }
  }, [contentsCsv, trackerCsv]);

  // Format CSV data and download file
  const formatCsv = (logs: any) => {
    const series = { ...trackerCsv?.series, event: true, localDate: true, localTime: true, place: true, user: true, id: true, tags: true, name: true };

    const rows = [];
    const headers: any = [];
    const headerLabels: any = {
      event: "Event",
      date: "Date",
      localDate: "Local Date",
      localTime: "Local Time",
      moved: "Moved",
      place: "Place",
      placeGroup: "Place Type",
      temperature: `Temperature (${printTempUnit()})`,
      voltage: "Voltage (V)",
      loadVoltage: "Load Voltage (V)",
      supplyVoltage: "Supply Voltage (V)",
      orientation: "Orientation",
      freshness: "Freshness (%)",
      light: "Light",
      lightInterrupt: "Light Interrupt",
      engineRunning: "Engine Running",
      network: "Network",
      timeOfFlight: "Time Of Flight (mm)",
      coupled: "on Tap",
      address: "Address",
      city: "City",
      state: "State",
      postcode: "Postcode",
      country: "Country",
      latitude: "Latitude",
      longitude: "Longitude",
      locationAccuracy: `Location Accuracy (${printLengthUnit()})`,
      locationType: "Location Type",
      user: "User",
      id: "ID",
      tags: "Tags",
      name: "Name",
    };

    // Function to get the value of a property safely, even if it doesn't exist
    const getProperty = (obj: any, prop: any) => {
      return prop.split(".").reduce((acc: any, curr: any) => {
        return acc && acc[curr];
      }, obj);
    };

    // Function to format values for specific properties
    const formatValue = (key: any, value: any) => {
      if (key === "moved") return value ? "True" : "False";
      else if (key === "temperature") return printFixedTemp(value);
      else if (key === "orientation") return value === "U" ? "Up" : "Down";
      else if (key === "freshness") return +value ? (+value).toFixed(1) : "";
      else if (key === "lightInterrupt") return value ? "True" : "False";
      else if (key === "engineRunning") return value ? "True" : "False";
      else if (key === "coupled") return value ? "True" : "False";
      else if (key === "locationAccuracy") return printFixedLength(value);
      else if (key === "event") return value ? value : "Tracker Data";
      return value;
    };

    // Function to format date in different ways
    const formatDate = (key: any, value: any) => {
      if (key === "date") return moment.unix(value).format();
      else if (key === "localDate") return moment.unix(value).format(short_date);
      else if (key === "localTime") return moment.unix(value).format(time);
    };

    // Construct headers with specific labels for series
    for (const key in headerLabels) {
      if (series[key]) {
        headers.push(headerLabels[key]);
      }
    }

    // Add header row
    rows.push(headers);

    // Add data rows
    logs.forEach((item: any) => {
      const row = [];
      for (const key in headerLabels) {
        if (series[key]) {
          if (key === "date" || key === "localDate" || key === "localTime") {
            const value = getProperty(item, "date") || "";
            row.push(formatDate(key, value));
          } else {
            const value = getProperty(item, key) || "";
            row.push(formatValue(key, value));
          }
        }
      }
      rows.push(row);
    });

    setTrackerCsvLoading(false);
    setContentsCsvLoading(false);

    downloadFile(
      stringify(rows, {
        quoted: true,
        quoted_string: true,
      }),
      "text/csv;charset=utf-8",
      `Manifest ${manifest.id}${manifest.name ? " - " + manifest.name : ""}.csv`
    );
  };

  const fetchTracker = (id: string) => {
    const dates = {
      start: manifest.startTimeUnix ? manifest.startTimeUnix : manifest.createdTimeUnix ? manifest.createdTimeUnix : moment().subtract(7, "days").unix(),
      end: manifest.endTimeUnix ? manifest.endTimeUnix : moment().unix(),
    };

    setManifestDates({ start: moment.unix(dates.start), end: moment.unix(dates.end) });

    if (dates && id !== undefined) {
      setTrackerErr("");

      fetchSensorPage(source, id, dates)
        .then((response) => {
          setTracker(response);

          if (response.history.length > 0) {
            // adds first log to new events
            const newEvents = [];

            // loops through all events
            for (let i = 0; i < response.history.length; i++) {
              const tempEvent = response.history[i];
              tempEvent.eventDate = Math.round(tempEvent.eventDate);
              if (tempEvent.eventDate >= dates.start && tempEvent.eventDate <= dates.end) {
                newEvents.push([tempEvent.eventDate, tempEvent, 0.5]);
              }
            }
            setEvents(newEvents);
          } else {
            setEvents([]);
          }

          setDataLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setTrackerErr(errToStr(err));
            setDataLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    fetchManifest();
  }, [manifestId]);

  useEffect(() => {
    if (manifest.sensorId) {
      setTrackerId(manifest.sensorId);
      fetchTracker(manifest.sensorId);
    }

    // Fetch manifest data when manifest is loaded
    if (manifest.id) {
      fetchManifestMapData();
      fetchManifestLogs();
    }
  }, [manifest]);

  return (
    <>
      <PageBreadcrumbs
        height="57px"
        prevRoutes={[
          {
            slug: `/manifests`,
            title: "Manifests",
          },
        ]}
        currRoute={manifest.name || manifestId}
        rightContent={
          width < 768 ? (
            <>
              {isAdminOrUser() && (
                <>
                  {manifest.startTimeUnix == null ? (
                    <Tooltip content="Start Manifest">
                      <PrimaryBtn
                        style={{
                          height: "40px",
                          margin: "0 6px",
                          minWidth: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        key="start"
                        onClick={() => {
                          setStartManifestModalOpen(true);
                        }}
                      >
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "16px" }}>
                          <FaPlay />
                        </IconContext.Provider>
                      </PrimaryBtn>
                    </Tooltip>
                  ) : manifest.endTimeUnix == null ? (
                    <Tooltip content="End Manifest">
                      <PrimaryBtn
                        style={{
                          height: "40px",
                          margin: "0 6px",
                          minWidth: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        key="end"
                        onClick={() => {
                          setEndManifestModalOpen(true);
                        }}
                      >
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "16px" }}>
                          <FaStop />
                        </IconContext.Provider>
                      </PrimaryBtn>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <Tooltip
                maxWidth="none"
                theme="binary-no-padding"
                content={
                  <MenuList>
                    {isAdminOrUser() && (
                      <MenuButton
                        onClick={() => {
                          setEditModalOpen(true);
                          hideAll();
                        }}
                      >
                        Edit Manifest
                      </MenuButton>
                    )}
                    {manifest.dateMarkedForPickupUnix == null && manifest.status === "Not Started" && (
                      <MenuButton
                        onClick={() => {
                          setMarkForPickupModalOpen(true);
                          hideAll();
                        }}
                      >
                        Mark for Pickup
                      </MenuButton>
                    )}
                    <MenuButton
                      onClick={() => {
                        fetchCsv();
                        hideAll();
                      }}
                    >
                      Download CSV
                    </MenuButton>
                  </MenuList>
                }
                interactive={true}
                touch={true}
                appendTo={document.body}
                trigger="click"
                placement="bottom-start"
              >
                <div style={{ display: "inline-block", margin: "12px 6px" }}>
                  <Tooltip content="More" hideOnClick={true} trigger="mouseenter">
                    <OutlineBtn key="edit" style={{ height: "40px", minWidth: "unset" }} padding="0 6px">
                      <div
                        style={{
                          padding: "4px",
                          width: "26px",
                          height: "26px",
                          lineHeight: "0",
                          margin: "0 2px",
                        }}
                      >
                        <MoreIcon fill={color.font[2]} />
                      </div>
                    </OutlineBtn>
                  </Tooltip>
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              {isAdminOrUser() && (
                <>
                  {manifest.startTimeUnix == null ? (
                    <PrimaryBtn
                      style={{ margin: "0px 6px" }}
                      onClick={() => {
                        setStartManifestModalOpen(true);
                      }}
                    >
                      Start
                    </PrimaryBtn>
                  ) : manifest.endTimeUnix == null ? (
                    <PrimaryBtn
                      style={{ margin: "0px 6px" }}
                      onClick={() => {
                        setEndManifestModalOpen(true);
                      }}
                    >
                      End
                    </PrimaryBtn>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {isAdminOrUser() && (
                <Tooltip content="Edit Manifest">
                  <OutlineBtn
                    style={{
                      height: "40px",
                      margin: "0 6px",
                      minWidth: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key="edit"
                    onClick={() => setEditModalOpen(true)}
                  >
                    <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                      <MdEdit />
                    </IconContext.Provider>
                  </OutlineBtn>
                </Tooltip>
              )}
              <Tooltip
                maxWidth="none"
                theme="binary-no-padding"
                content={
                  <MenuList>
                    {manifest.dateMarkedForPickupUnix == null && manifest.status === "Not Started" && (
                      <MenuButton
                        onClick={() => {
                          setMarkForPickupModalOpen(true);
                          hideAll();
                        }}
                      >
                        Mark for Pickup
                      </MenuButton>
                    )}
                    <MenuButton
                      onClick={() => {
                        fetchCsv();
                        hideAll();
                      }}
                    >
                      Download CSV
                    </MenuButton>
                  </MenuList>
                }
                interactive={true}
                touch={true}
                appendTo={document.body}
                trigger="click"
                placement="bottom-start"
              >
                <div style={{ display: "inline-block", margin: "12px 6px" }}>
                  <Tooltip content="More" hideOnClick={true} trigger="mouseenter">
                    <OutlineBtn key="edit" style={{ height: "40px", minWidth: "unset" }} padding="0 6px">
                      <div
                        style={{
                          padding: "4px",
                          width: "26px",
                          height: "26px",
                          lineHeight: "0",
                          margin: "0 2px",
                        }}
                      >
                        <MoreIcon fill={color.font_bold[2]} />
                      </div>
                    </OutlineBtn>
                  </Tooltip>
                </div>
              </Tooltip>
            </>
          )
        }
      />
      <PageContainer top="57px" slim={true}>
        <LoadingContainer
          loading={contentsCsvLoading || trackerCsvLoading || manifestLogsLoading}
          err={manifestErr || trackerErr || manifestMapErr || manifestLogsErr}
        >
          {!manifestErr && !trackerErr && !manifestMapErr && !manifestLogsErr && (
            <>
              <FlexRow>
                <MetaWrapper>
                  <ManifestMeta
                    trackerId={trackerId}
                    trackerMeta={tracker.meta}
                    trackerSeries={series}
                    trackerLogs={manifestLogs}
                    manifestMeta={manifest}
                    setData={setManifest}
                    dataLoading={dataLoading}
                    fetchData={fetchManifest}
                  />
                </MetaWrapper>
                <MapWrapper>
                  <ManifestMap data={manifestMapData} dataLoading={dataLoading || manifestMapLoading} dataErr={manifestMapErr} />
                </MapWrapper>
              </FlexRow>
              <FlexRow>
                <ChartWrapper>
                  <ChartHeading>Contents</ChartHeading>
                  <ManifestContentsTable manifestId={manifestId} placeId={tracker?.meta?.placeId} placeName={tracker?.meta?.place} />
                </ChartWrapper>
              </FlexRow>
              <FlexRow>
                <ChartWrapper>
                  <SensorLogs
                    logs={manifestLogs}
                    pieces={pieces}
                    events={events}
                    meta={meta}
                    loading={manifestLogsLoading}
                    series={series}
                    setCursor={setCursor}
                  />
                </ChartWrapper>
              </FlexRow>
              <FlexRow>
                <HistoryWrapper>
                  <SensorHistory data={tracker.history} loading={dataLoading} />
                </HistoryWrapper>
              </FlexRow>
              {manifestDates !== undefined && (
                <FlexRow>
                  <AlertWrapper>
                    <ChartHeading>Alert Notifications</ChartHeading>
                    <AlertLogsTable sensorId={trackerId} filterDates={manifestDates} />
                  </AlertWrapper>
                </FlexRow>
              )}
            </>
          )}
        </LoadingContainer>
      </PageContainer>
      {editModalOpen && <EditManifestModel manifest={manifest} onSuccess={fetchManifest} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {startManifestModalOpen && (
        <StartManifestModel manifest={manifest} onSuccess={fetchManifest} modalOpen={startManifestModalOpen} setModalOpen={setStartManifestModalOpen} />
      )}
      {endManifestModalOpen && (
        <EndManifestModal
          manifest={manifest}
          onSuccess={fetchManifest}
          placeId={tracker?.meta?.placeId}
          placeName={tracker?.meta?.place}
          modalOpen={endManifestModalOpen}
          setModalOpen={setEndManifestModalOpen}
        />
      )}
      {markForPickupModalOpen && (
        <ManifestMarkForPickupModal
          manifestId={manifest.id}
          onSuccess={() => fetchManifest()}
          modalOpen={markForPickupModalOpen}
          setModalOpen={setMarkForPickupModalOpen}
        />
      )}
    </>
  );
};

export default ManifestScreen;
